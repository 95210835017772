.app {
  text-align: center;
  height: 100%;
  min-height: 100vh;
  background-image: url(./assets/img/site_background.jpg);
  background-size: cover;
  background-position: 500px;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@media (max-width: 1130px) {
  .app {
    background-position: 300px;
  }
}

@media (max-width: 950px) {
  .app {
    background-position: 150px;
  }
}

@media (max-width: 800px) {
  .app {
    background-position: 100px;
  }
}
@media (max-width: 700px) {
  .app {
    background-position: 0px;
  }
}
@media (max-width: 600px) {
  .app {
    background-position: -50px;
  }
}
@media (max-width: 500px) {
  .app {
    background-position: -100px;
  }
}
@media (max-width: 400px) {
  .app {
    background-position: -150px;
  }
}