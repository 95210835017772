.main{
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: flex-start;
}
@media (max-width: 600px){
    .main{
        width: 100%;
        color: black;
    }
}
.main h3{
    margin: 0;
    padding: 16px;
}
.main h1{
    margin: 0;
}





