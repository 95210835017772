.main{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.main h3{
    margin: 0;
    padding: 16px;
}
.main h1{
    margin: 0;
}
.links{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 630px;
    margin: 0 0 0 10%;
}

.titles{
    margin-left: 10%;
    width: 630px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


@media (max-width:550px){
    .links{
        flex-direction: column;
    }


}

@media (max-width:700px){
    .titles{
        margin: 0;
        width: 100%;
    }
    .links{
        margin: 0;
        width: 100%;
    }
}



